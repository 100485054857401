export default function CdcInfo() {
  return (
    <div className='cdc-footer-mobile'>
      <ul className='link-list' aria-label='CDC INFORMATION'>
        <li>
          <a href='https://www.cdc.gov/about/default.htm' target='_blank' rel='noopener noreferrer'>
            About CDC
          </a>
        </li>
        <li>
          <a href='https://jobs.cdc.gov/' target='_blank' rel='noopener noreferrer'>
            Jobs
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/funding/' target='_blank' rel='noopener noreferrer'>
            Funding
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/other/plugins/index.html' target='_blank' rel='noopener noreferrer'>
            File View & Players
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/Other/policies.html' target='_blank' rel='noopener noreferrer'>
            Policies
          </a>
        </li>
      </ul>
    </div>
  )
}
