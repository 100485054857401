import { Header } from 'semantic-ui-react'

export default function Connect() {
  return (
    <div className='cdc-footer-mobile'>
      <Header
        as='h4'
        content='CONNECT WITH CDC'
        textAlign='left'
        style={{ color: '#ffffff', fontSize: 13, margin: 0 }}
      />
      <ul className='list-inline mt-3 social-media1'>
        <li>
          <a href='https://www.facebook.com/CDC' target='_blank' rel='noopener noreferrer'>
            <span className='sr-only'>Facebook</span>
            <span className='fi cdc-icon-fb-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/CDCgov' target='_blank' rel='noopener noreferrer'>
            <span className='sr-only'>Twitter</span>
            <span className='fi cdc-icon-twitter-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/CDCgov/' target='_blank' rel='noopener noreferrer'>
            <span className='sr-only'>Instagram</span>
            <span className='fi cdc-icon-insta x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a
            href='https://www.linkedin.com/company/centers-for-disease-control-and-prevention'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='sr-only'>LinkedIn</span>
            <span className='fi cdc-icon-linkedin-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a href='https://www.snapchat.com/add/cdcgov' target='_blank' rel='noopener noreferrer'>
            <span className='sr-only'>Snapchat</span>
            <span className='fi cdc-icon-snapchat x24' aria-hidden='true' />
          </a>
        </li>
      </ul>
      <ul className='list-inline mt-2 social-media1'>
        <li>
          <a href='https://www.youtube.com/user/CDCstreamingHealth' target='_blank' rel='noopener noreferrer'>
            <span className='sr-only'>Youtube</span>
            <span className='fi cdc-icon-youtube-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a
            href='https://tools.cdc.gov/medialibrary/index.aspx#/sharecontent/https://www.cdc.gov/index.htm'
            className='footer-syndlink'
          >
            <span className='sr-only'>Syndicate</span>
            <span className='fi cdc-icon-syndication-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/cdctv'>
            <span className='sr-only'>CDC TV</span>
            <span className='fi cdc-icon-cdc-tv-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a href='https://tools.cdc.gov/podcasts/rss.asp'>
            <span className='sr-only'>RSS</span>
            <span className='fi cdc-icon-rss-white x24' aria-hidden='true' />
          </a>
        </li>
        <li>
          <a href='https://wwwn.cdc.gov/dcs/RequestForm.aspx'>
            <span className='sr-only'>Email</span>
            <span className='fi cdc-icon-email x24' aria-hidden='true' />
          </a>
        </li>
      </ul>
    </div>
  )
}
