import { ErrorBanner } from '@/layouts/Navbar/components'
import './PowerBIPage.css'

export default function PowerBIPage() {
  return (
    <div className='main'>
      <div className='App-header'>
        <iframe
          title='DPRP_Reports'
          width='100%'
          height='100%'
          src='https://app.powerbigov.us/reportEmbed?reportId=97598283-e91f-4e03-97dc-6658766f805f&autoAuth=true&ctid=9ce70869-60db-44fd-abe8-d2767077fc8f'
          frameBorder='0'
          allowFullScreen='true'
        >
          empty
        </iframe>
      </div>
      <ErrorBanner />
    </div>
  )
}
