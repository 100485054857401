import { Button, Icon } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

export default function Help() {
  const navigate = useNavigate()

  const routeChange = () => {
    // 508 support going back, closing the help
    if (window.location.href.endsWith('help')) navigate('/')
    else navigate('/help')
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}>
      <Button
        aria-label='help button'
        style={{ backgroundColor: 'transparent', padding: '0.5rem' }}
        onClick={routeChange}
      >
        <Icon
          name='question circle outline' // 'ellipsis vertical'
          size='big' // logout uses 'large'
          fitted
          style={{ color: '#ffffff', opacity: '0.9' }}
        />
      </Button>
    </div>
  )
}
