import Helmet from 'react-helmet'
import { AppErrorBoundary } from '@/components'
import { SamsLoginPage } from '@/containers'

import './SamsLogin.css'

export default function SamsLogin() {
  return (
    <AppErrorBoundary>
      <div className='Help'>
        <Helmet
          title='National DPP Ops Center'
          meta={[
            { charset: 'utf-8' },
            {
              'http-equiv': 'X-UA-Compatible',
              content: 'IE=edge'
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1'
            }
          ]}
        />
        <SamsLoginPage />
      </div>
    </AppErrorBoundary>
  )
}
