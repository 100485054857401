import { Grid, Icon } from 'semantic-ui-react'

export default function Disclaimer() {
  return (
    <Grid columns={3} style={{ width: '100%', margin: 0 }}>
      <Grid.Row className='second-footer' centered>
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <a href='https://www.cdc.gov/Other/privacy.htmls' target='_blank' rel='noopener noreferrer'>
            U.S. Department of Health & Human Services
          </a>
        </Grid.Column>
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <a href='https://www.cdc.gov/Other/privacy.htmls' target='_blank' rel='noopener noreferrer'>
            USA.gov
          </a>
        </Grid.Column>
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <a href='https://www.cdc.gov/Other/privacy.htmls' target='_blank' rel='noopener noreferrer'>
            CDC Website Exit Disclaimer <Icon name='external alternate' />
          </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
