// React, {}
import React, { useEffect } from 'react'
// import { ModalActions } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

import axios from 'axios'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { GetEnvironment } from '../lib/getEnvironment'
import * as actions from '../redux/actions'

// , actions
function SamsCallBack({ user, actions }) {
  const navigate = useNavigate()

  useEffect(() => {
    let SamsEnvironment = ''

    // eslint-disable-next-line
    console.log('SamsCallack useEffect')

    const params = new URLSearchParams(window.location.search)
    const samsCode = params.get('code')

    // eslint-disable-next-line
    console.log('SamsCode: ' + samsCode)

    if (!samsCode) {
      // eslint-disable-next-line
      console.log('samsCode is null, SAMS auth failed')
    } else {
      // eslint-disable-next-line
      console.log('SAMS auth success')

      /* nigel emailed:
      {
              "SamsCode":””,
              "SamsActivity": "DdtOpsCenter",
              "SamsEnvironment": "Dev"
      }
      */
      const environment = GetEnvironment()
      if (environment === 'local') {
        SamsEnvironment = 'Local'
      }
      if (environment === 'dev') {
        SamsEnvironment = 'Dev'
      }
      if (environment === 'qa') {
        SamsEnvironment = 'QA'
      }
      if (environment === 'int') {
        SamsEnvironment = 'Int'
      }
      if (environment === 'prod') {
        SamsEnvironment = 'Prod'
      }
      // eslint-disable-next-line
      const Data = {
        SamsCode: samsCode,
        SamsActivity: 'DdtOpsCenter', // tbd troy 'NassClinicDashboard'
        SamsEnvironment
      }

      // eslint-disable-next-line
      console.log('Verifing token via post to api/SamsUser')

      axios({
        method: 'post',
        // url: 'https://localhost:44302/api/SamsUser',
        // url: '/SamsAPI/api/SamsUser',
        url: '/api/SamsUser',
        data: Data
        // headers: {"Access-Control-Allow-Origin": "*"}
      })
        .then(resp => {
          // eslint-disable-next-line
          console.log(resp.data)
          // eslint-disable-next-line
          console.log('SamsCallback set user.loggedin true')
          actions.setUser({ email: resp.data.SamsResponse.email, loggedIn: true, samsCode })

          const cookies = new Cookies()
          // expire cookie after 45 minutes
          cookies.set('opsCenterUser', resp.data.SamsResponse.email, { path: '/', maxAge: 45 * 60 })
          // eslint-disable-next-line
          console.log('cookie opsCenterUser: ' + cookies.get('opsCenterUser'))

          navigate('/')
          // alert(resp.data.SamsResponse.email)
          // setUserEmail(resp.data.email)
        })
        .catch(err => {
          // eslint-disable-next-line
          console.error('SamsCallback post to api/SamsUser err: ', err)
          // troy todo, prevent a successful login if not dev
          if (environment === 'dev') {
            const devEmail = 'tev8@cdc.gov'
            // eslint-disable-next-line
            console.error('dev, so continue as user: ', devEmail)
            actions.setUser({ email: devEmail, loggedIn: true, samsCode })
            const cookies = new Cookies()
            // expire cookie after 45 minutes
            cookies.set('opsCenterUser', devEmail, { path: '/', maxAge: 45 * 60 })
            navigate('/')
          }
        })
    }
  }, [user, actions])

  return (
    <div>
      <br />
      {/* <span>Redirecting to {this.target}</span> */}
      {/* <span>Redirecting to Google</span> */}
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SamsCallBack)
