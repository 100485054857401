/* eslint-disable max-len */
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

export default function SEO({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta name='author' content='National DPP' />
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string
}

SEO.defaultProps = {
  description:
    'The National Diabetes Prevention Program (National DPP) was created to address the increasing burden of prediabetes and type 2 diabetes in the United States. This national effort created partnerships between public and private organizations to offer evidence-based, cost-effective interventions that help prevent type 2 diabetes in communities across the United States',
  keywords:
    'Centers for Disease Control and Prevention, National Center for Chronic Disease Prevention and Health Promotion, Division of Diabetes Translation'
}
