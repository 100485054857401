import PropTypes from 'prop-types'
import { Card } from 'semantic-ui-react'
import './ModuleCard.css'
import { initMetricInteraction } from '@/lib/analytics'

export default function ModuleCard({ app }) {
  const { name, path, icon, target } = app

  // function doAlert() {
  //   // eslint-disable-next-line no-alert
  //   alert(name)
  // }

  const logTileClick = () => {
    // eslint-disable-next-line no-alert
    initMetricInteraction('DDT-OPS Menu Tile', name)
  }

  const content = (
    <Card.Content>
      <div className='app-screenshot' style={{ backgroundImage: `url(${icon})` }} />
      <div className='app-name'>
        <span>{name}</span>
      </div>
    </Card.Content>
  )

  return (
    <Card
      as='a'
      className='module-card'
      href={path}
      rel='noopener noreferrer'
      tabIndex={0}
      role='button'
      onClick={logTileClick}
      target={target}
    >
      {content}
    </Card>
  )
}

ModuleCard.propTypes = {
  app: PropTypes.object.isRequired
}
