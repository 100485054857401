import { useRef } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Carousel.css'

const rotatingText = [
  {
    id: 1,
    text: 'More than 37 million people in the United States have diabetes, and 1 in 5 of them don’t know they have it.'
  },
  {
    id: 2,
    text:
      'More than 96 million US adults—1 in 3—have prediabetes, and more than 8 in 10 of them don’t know they have it.'
  },
  {
    id: 3,
    text: `Prediabetes is a risk factor for type 2 diabetes. Being overweight, being age 45 or older, \
      and being physically active less than 3 times a week are risk factors for prediabetes and type 2 diabetes.`
  },
  {
    id: 4,
    text: `In 2017, the total estimated cost of diagnosed diabetes was $327 billion.`
  },
  {
    id: 5,
    text: `CDC focuses on preventing type 2 diabetes, reducing diabetes complications and disability, and reducing \
      diabetes‑related disparities, which are differences in health across different geographic, racial, ethnic, \
      and socioeconomic groups.`
  }
]

export default function Carousel() {
  const slider = useRef(null)

  // Pause autoplay when dots div is focused (for 508 compliance)
  const handleFocus = () => {
    slider.current.slickPause()
  }

  // Resume autoplay when dots div loses focus
  const handleBlur = () => {
    slider.current.slickPlay()
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    accessibility: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    appendDots: dots => (
      <div style={{ textAlign: 'left' }} onFocus={handleFocus} onBlur={handleBlur}>
        <ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
      </div>
    )
  }

  return (
    <Grid className='message-carousel' container stackable>
      <div className='carousel-container'>
        <Grid.Row style={{ padding: 3 }}>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <Slider ref={slider} {...settings}>
              {rotatingText.map(message => (
                <div key={message.id} className='carousel-text'>
                  <Grid.Row>
                    <Grid.Column computer={14} mobile={16}>
                      <Header as='h4' style={{ fontFamily: 'RobotoBlack' }}>
                        {message.text}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </div>
              ))}
            </Slider>
          </Grid.Column>
        </Grid.Row>
      </div>
    </Grid>
  )
}
