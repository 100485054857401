import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'

// import { useAppStatus } from '@/hooks'
// import logger from '@/lib/logger'
import AppErrorFallback from './AppErrorFallback'

export default function AppErrorBoundary({ children, handleReset }) {
  // const [, updateAppStatus] = useAppStatus()
  const [, updateAppStatus] = []

  const handleError = (error, info) => {
    if (error) {
      // if (error && info.componentStack) {
      //   //logger.fatal(error.toString(), info.componentStack)
      // } else {
      //   //logger.fatal('Application encountered a fatal error', error)
      // }
      updateAppStatus({ message: { error, info }, hasError: true })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={AppErrorFallback} onError={handleError} onReset={handleReset}>
      {children}
    </ErrorBoundary>
  )
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node,
  handleReset: PropTypes.func
}

AppErrorBoundary.defaultProps = {
  children: null,
  // eslint-disable-next-line no-console
  handleReset: () => console.log('Error Boundary onReset')
}
