// import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { ErrorBanner } from '@/layouts/Navbar/components'
import Cookies from 'universal-cookie'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { doAnalyticsWithTimeout } from '@/lib/analytics'
import * as actions from '../../redux/actions'
import { Carousel, AvailableApps } from './components'
import './LandingPage.css'

function LandingPage({ user, actions }) {
  const skipAuth = process.env.REACT_APP_SAMS_BYPASS === 'true'
  let userEmail = user.email
  const userRoleIds = [] // [] - assume user not found in database
  const userStates = []
  const userOrgIdsByState = []

  // Start Analytics
  doAnalyticsWithTimeout({
    levels: ['Chronic', 'DDT', 'diabetes', 'NDPP'],
    AzEntry: null,
    channel: 'DDT NDPP'
  })
  // End Analytics
  // eslint-disable-next-line
  console.log('LandingPage user.loggedIn ' + user.loggedIn)
  // eslint-disable-next-line
  console.log('LandingPage user.skipAuth ' + skipAuth)

  if (!skipAuth && !user.loggedIn) {
    const cookies = new Cookies()
    const opsCenterUser = cookies.get('opsCenterUser')
    // eslint-disable-next-line
    console.log('cookie opsCenterUser: ' + opsCenterUser)
    if (opsCenterUser && opsCenterUser !== '') {
      actions.setUser({ email: opsCenterUser, loggedIn: true })
      userEmail = opsCenterUser
    } else {
      window.location.href = '/login'
      return <div />
    }
  }

  // if (!skipAuth && !user.loggedIn) {
  //   window.location.href = '/login'
  //   return <div />
  // }

  if (skipAuth && !user.loggedIn) {
    // assume dev eenv
    // eslint-disable-next-line
    console.log('dev: SamsCallback set user.loggedin true, email devtest.apifailed@cdc.gov')
    actions.setUser({ email: 'devtest.apifailed@cdc.gov', loggedIn: true, samsCode: 'apifailedlocal' })

    const cookies = new Cookies()
    cookies.set('opsCenterUser', 'devtest.apifailed@cdc.gov', { path: '/' })
    // eslint-disable-next-line
    console.log('cookie opsCenterUser: ' + cookies.get('opsCenterUser'))
  }

  // eslint-disable-next-line
  console.log('Home userEmail: ' + userEmail)

  let apiUrl = process.env.REACT_APP_DDT_OPS_API_ROSE_URL_BASE
  if (!apiUrl) apiUrl = '/edav-api/ROSE' // in ROSE api

  // eslint-disable-next-line
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line
  const [error, setError] = useState('')
  useEffect(() => {
    // eslint-disable-next-line
    const url = apiUrl + '/GetAppAuthByEmail/' + userEmail
    // eslint-disable-next-line
    console.log(url)
    // eslint-disable-next-line
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // eslint-disable-next-line
        console.log(data)
        if (data.errorMsg) {
          setError(data.errorMsg)
          setLoading(false)
        } else {
          for (let i = 0; i < data.length; i++) {
            const { email, roleId, organizationId, stateAbbr } = data[i]
            // eslint-disable-next-line
            console.log(email + ', ' + roleId + ', ' + organizationId + ', ' + stateAbbr)

            if (!userRoleIds.includes(roleId)) {
              userRoleIds.push(roleId)
              // users can have multiple roles
            }

            const ind = userStates.indexOf(stateAbbr)
            if (ind >= 0) {
              userOrgIdsByState[ind].push(organizationId)
            } else {
              // // eslint-disable-next-line
              // console.log('Home add state: ' + stateAbbr)
              userStates.push(stateAbbr)
              userOrgIdsByState.push([organizationId])

              // // eslint-disable-next-line
              // console.log('Home add userStates.length: ' + userStates.length)
            }
          }

          if (userRoleIds.length === 0) {
            // eslint-disable-next-line
            console.log('User not in authorizations table, assume 10.')
            userRoleIds.push(10)
          }

          // eslint-disable-next-line
          console.log('Home userRoleIds: ' + userRoleIds.toString())
          if (userOrgIdsByState.length > 0) {
            // eslint-disable-next-line
            console.log('Home userOrgIdsByState[0].length: ' + userOrgIdsByState[0].length)
          }

          actions.setUser({
            email: userEmail,
            loggedIn: true,
            samsCode: user.samsCode,
            roleIds: userRoleIds,
            states: userStates,
            orgIdsByState: userOrgIdsByState
          })

          setLoading(false)
        }
      })
      .catch(err => {
        setError(err.message)
        // eslint-disable-next-line
        console.log(err.message)
        setLoading(false)
      })
  }, [])

  return (
    <div className='main'>
      <div className='App-header'>
        <Grid className='landing-page-container' stackable>
          <Grid.Row>
            <Grid.Column className='app-menu' computer={6} mobile={16} tablet={6}>
              <Carousel />
            </Grid.Column>
            <Grid.Column className='app-menu' computer={10} tablet={10} mobile={16}>
              <AvailableApps />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <ErrorBanner />
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
