import { Dropdown } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../../redux/actions'

function User({ user }) {
  const navigate = useNavigate()
  const options = [
    {
      key: 'user',
      text: (
        <span>
          <strong>Hello, {user.email}!</strong>
        </span>
      ),
      disabled: true
    },
    {
      key: 'sign-out',
      text: '',
      icon: 'sign out',
      value: 'sign-out',
      content: 'Sign Out'
    }
  ]

  // eslint-disable-next-line
  const handleChange = e => {
    // alert(e.target.value)
    navigate('/logout')
  }

  // console.log(user)

  return (
    user.loggedIn && (
      <div style={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}>
        <Dropdown
          aria-label='User options'
          icon={{ name: 'ellipsis vertical', size: 'large' }}
          options={options}
          className='icon'
          button
          style={{ color: '#ffffff', backgroundColor: 'transparent' }}
          pointing='top right'
          onChange={handleChange}
          openOnFocus={false}
          selectOnBlur={false}
          selectOnNavigation={false}
        />
      </div>
    )
  )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
