import { memo } from 'react'

const version = process.env.REACT_APP_VERSION

const AboutModal = memo(() => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <div style={{ margin: '3.5rem 2rem', textAlign: 'center' }}>
      <h3>App Version</h3>
      <p>{version}</p>
    </div>
  </div>
))

export default AboutModal
