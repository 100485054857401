/* eslint-disable max-len */
import { useState, useEffect } from 'react'
import { Message } from 'semantic-ui-react'

// import { useAppStatus } from '@/hooks'
// import logger from '@/lib/logger'

export default function ErrorBanner() {
  const [isError, setIsError] = useState(null)
  const [isDismissed, setIsDismissed] = useState(false)
  const [isIE] = useState(window.document.documentMode)

  // const [{ hasError }] = useAppStatus()
  const [{ hasError }] = [false] // troy was []

  useEffect(() => {
    if (isIE) {
      setIsError({
        header: 'We have detected that you are using Internet Explorer.',
        content:
          'For the best experience, please open using Chrome or Edge. Some features may be limited on Internet Explorer.'
      })
    }
  }, [isIE])

  useEffect(() => {
    if (hasError && isError && !isDismissed) {
      // logger.error(hasError)
      setIsError({
        header: 'We have encountered an error.',
        content: ' Some functionality may be limited at this time.'
      })
    }
  }, [hasError, isError, isDismissed])

  const handleDismiss = () => {
    setIsDismissed(true)
    setIsError(null)
  }

  return (
    isError && (
      <div className='map-overlay' style={{ top: 0, width: '100vw' }}>
        <Message
          color='red'
          onDismiss={handleDismiss}
          header={isError.header}
          content={isError.content}
          style={{
            width: '55%',
            textAlign: 'left',
            margin: 'auto',
            zIndex: 1004
          }}
        />
      </div>
    )
  )
}
