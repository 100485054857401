export default function Privacy() {
  return (
    <div className='cdc-footer-mobile'>
      <ul className='link-list'>
        <li>
          <a href='https://www.cdc.gov/Other/privacy.htmls' target='_blank' rel='noopener noreferrer'>
            Privacy
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/od/foia/' target='_blank' rel='noopener noreferrer'>
            FOIA
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/eeo/nofearact/index.htm' target='_blank' rel='noopener noreferrer'>
            No Fear Act
          </a>
        </li>
        <li>
          <a href='https://oig.hhs.gov/' target='_blank' rel='noopener noreferrer'>
            OIG
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/other/nondiscrimination.html' target='_blank' rel='noopener noreferrer'>
            Nondiscrimination
          </a>
        </li>
        <li>
          <a href='https://www.cdc.gov/contact/accessibility.html' target='_blank' rel='noopener noreferrer'>
            Accessibility
          </a>
        </li>
        <li>
          <a
            href='https://www.hhs.gov/vulnerability-disclosure-policy/index.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            Vulnerability Disclosure Policy
          </a>
        </li>
      </ul>
    </div>
  )
}
