import { SEO } from '@/components'
import { Navbar, Footer } from '.'

import './Default.css'

export default function Default({ children }) {
  const pageTitle = 'National DPP Ops Center'
  return (
    <>
      <Navbar />
      <div className='flex-layout relative'>
        <SEO title={pageTitle} />
        <div className='flex-layout main'>{children}</div>
      </div>
      <Footer />
    </>
  )
}
