import { Grid } from 'semantic-ui-react'
import { Questions, Info, Disclaimer, Privacy, Connect } from './components'

import './Footer.css'

export default function Footer() {
  return (
    <>
      <div className='cdc-footer'>
        <Grid className='main-grid' centered>
          <Grid.Row>
            <Grid.Column textAlign='center' computer={4} mobile={8}>
              <Questions />
            </Grid.Column>
            <Grid.Column textAlign='center' computer={4} mobile={8}>
              <Info />
            </Grid.Column>
            <Grid.Column textAlign='center' computer={4} mobile={8}>
              <Privacy />
            </Grid.Column>
            <Grid.Column computer={3} mobile={8}>
              <Connect />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Disclaimer />
    </>
  )
}
