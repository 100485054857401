import Helmet from 'react-helmet'
import { AppErrorBoundary } from '@/components'
import { PowerBIPage } from '@/containers'

import './PowerBI.css'

export default function PowerBI() {
  return (
    <AppErrorBoundary>
      <div className='PowerBI'>
        <Helmet
          title='National DPP Ops Center'
          meta={[
            { charset: 'utf-8' },
            {
              'http-equiv': 'X-UA-Compatible',
              content: 'IE=edge'
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1'
            }
          ]}
        />
        <PowerBIPage />
      </div>
    </AppErrorBoundary>
  )
}
