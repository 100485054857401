import { Header, Icon } from 'semantic-ui-react'

// TODO: style to be centered on all containers
export default function AppErrorFallback() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', margin: '1rem' }}>
      <Header as='h3' style={{ fontFamily: 'RobotoRegular' }}>
        <Icon name='warning circle' />
        Something went wrong.
      </Header>
      <Header
        as='h4'
        content='Please try refreshing the page. If the error persists, contact IT help for support.'
        style={{ margin: 0, fontFamily: 'RobotoRegular' }}
      />
    </div>
  )
}
