import { useLocation, Link } from 'react-router-dom'
import { Menu, Header, Grid, Image } from 'semantic-ui-react'

import cdcLogo from '@/assets/images/logos/additional-homepage-1.svg'
import { FormatPageTitle, ErrorBanner, User, Help } from './components'

import './Navbar.css'

// TODO: this needs to be responsive to vp sizes
function Navbar() {
  const { pathname } = useLocation()
  // const user = credentials || null

  return (
    <div>
      <div className='cdc-nav'>
        <Menu className='ddt-nav'>
          <div className='cdc-nav-header'>
            <Grid style={{ width: '100%', margin: 0 }}>
              <Grid.Row>
                <Grid.Column
                  className='header-img-column'
                  width={16}
                  stretched
                  textAlign='right'
                  floated='left'
                  style={{ paddingRight: 0 }}
                >
                  <Image src={cdcLogo} alt='CDC Logo' style={{ margin: 'auto' }} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Menu>
      </div>

      <div className='cdc-nav'>
        <Menu className='cdc-nav-menu'>
          <div className='cdc-nav-header'>
            <Link to='/'>
              <Grid className='ndpp-title'>
                <Grid.Row>
                  <Grid.Column width={16} verticalAlign='bottom'>
                    <h1 className='roboto-thin'>National DPP</h1>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <h2 className='roboto-thin'>Operations Center</h2>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Link>
          </div>
          <div className='cdc-nav-page'>
            <Header style={{ paddingRight: '1rem' }}>
              <Header.Content className='roboto-regular page-name'>
                <FormatPageTitle path={pathname} />
              </Header.Content>
            </Header>
          </div>
          <Help />
          <User />
          <ErrorBanner />
        </Menu>
      </div>
    </div>
  )
}

export default Navbar
