import { Grid } from 'semantic-ui-react'
import cam from '@/assets/images/screenshots/community-assets.png'
import se from '@/assets/images/screenshots/site-explorer.png'
import tracker from '@/assets/images/screenshots/impact-tacker.png'
import orgRisk from '@/assets/images/screenshots/org-risk.png'

import dprpCdcReports from '@/assets/images/screenshots/DPRP-CDC-Reports.jpg'
import dprpSqsReports from '@/assets/images/screenshots/DPRP-SQS-Reports.jpg'
import dprpPqsReports from '@/assets/images/screenshots/DPRP-PQS-Reports.jpg'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../../../redux/actions'

/*
JT - 27.APR   Removed to transition to QA ENV
 import gqlPlayground from '@/assets/images/screenshots/graphiql-playground.png'
import dash from '@/assets/images/screenshots/data-discovery.png'
import orgRisk from '@/assets/images/screenshots/org-risk.png'
import social from '@/assets/images/screenshots/SocialListeningWireframeDraft.png'
import digital from '@/assets/images/screenshots/digital-insights.png'
import p2p from '@/assets/images/screenshots/path-2-prevention.png'
import bright from '@/assets/images/screenshots/bright-spots.png'
*/

import { ModuleCard } from './components'

import './AvailableApps.css'

const defaultUrl = 'http://localhost:3000'
// default to prod URLs for PBI
let pbiSqsURL = process.env.REACT_APP_URL_PBI_SQS_PROD
let pbiCdcURL = process.env.REACT_APP_URL_PBI_CDC_PROD
let pbiPqsURL = process.env.REACT_APP_URL_PBI_PQS_PROD
let pbiRamURL = process.env.REACT_APP_URL_PBI_RAM_PROD
let pbiSqsRamURL = process.env.REACT_APP_URL_PBI_SQS_RAM_PROD

const ENV_PROD = 'PROD'
const ENV_INT = 'INT'
const ENV_QA = 'QA'
const ENV_DEV = 'DEV'
const ENV_LOCAL = 'LOCAL'

/*
Returns the current environment as const
*/
function getEnvironment() {
  const currentEnv = window.location.origin
  if (currentEnv.includes('localhost')) return ENV_LOCAL
  if (currentEnv.includes('-qa.apps.ecpaas-dev.cdc.gov')) return ENV_QA
  if (currentEnv.includes('ddt-ops-center.apps.ecpaas-dev.cdc.gov')) return ENV_DEV
  if (currentEnv.includes('-int.cdc.gov')) return ENV_INT
  return ENV_PROD
}

/*
determines the environment based on the url opened by the window.
Loads corresponding environment variable.
Sets global variables pbiCdcURL and pbiSqsURL
*/
function setURLsforEnv() {
  const env = getEnvironment()
  if (env === ENV_PROD || env === ENV_INT) {
    pbiSqsURL = process.env.REACT_APP_URL_PBI_SQS_PROD
    pbiCdcURL = process.env.REACT_APP_URL_PBI_CDC_PROD
    pbiPqsURL = process.env.REACT_APP_URL_PBI_PQS_PROD
    pbiRamURL = process.env.REACT_APP_URL_PBI_RAM_PROD
    pbiSqsRamURL = process.env.REACT_APP_URL_PBI_SQS_RAM_PROD
  } else {
    pbiSqsURL = process.env.REACT_APP_URL_PBI_SQS_DEV
    pbiCdcURL = process.env.REACT_APP_URL_PBI_CDC_DEV
    pbiPqsURL = process.env.REACT_APP_URL_PBI_PQS_DEV
    pbiSqsRamURL = process.env.REACT_APP_URL_PBI_SQS_RAM_DEV
  }
  // eslint-disable-next-line
  console.log('ENV: ' + env)
  // eslint-disable-next-line
  console.log('SQS: ' + pbiSqsURL)
  // eslint-disable-next-line
  console.log('CDC: ' + pbiCdcURL)
  // eslint-disable-next-line
  console.log('PQS: ' + pbiPqsURL)
  // eslint-disable-next-line
  console.log('RAM: ' + pbiRamURL)
  // eslint-disable-next-line
  console.log('SQS RAM: ' + pbiSqsRamURL)
  // eslint-disable-next-line
  console.log(process.env)
}

function AvailableApps({ user }) {
  // 6.16.2023 JVT - sets global variables for pbi paths from env vars
  setURLsforEnv()

  // eslint-disable-next-line
  // const loginSamsCode = '/opsredirect?code=' + user.samsCode
  const loginSamsCode = '/opsredirect'
  // const loginSamsCode = ''
  let showCAM = false
  let urlCAM = ''
  // const base = useBasePath()
  const base = []
  // eslint-disable-next-line
  const urlPowerBI = base + '/powerbi'
  const apps = []
  let showOrgRisk = false
  let urlOrgRisk = ''

  /*
  // eslint-disable-next-line
  console.log('window.location.origin: ' + window.location.origin)
  // eslint-disable-next-line
  if (window.location.origin === 'https://ddt-ops-center-qa.apps.ecpaas-dev.cdc.gov') {
    showCAM = true
    // 6.16.2023 JVT read from env
    urlCam = process.env.URL_CAM_DEV;
    //urlCAM = 'https://ddt-community-assets.apps.ecpaas-dev.cdc.gov/'
  } else if (window.location.origin === 'https://ddt-ops-center.apps.ecpaas-dev.cdc.gov') {
    showCAM = true
    // 6.16.2023 JVT read from env
    urlCam = process.env.URL_CAM_DEV;
    //urlCAM = 'https://ddt-community-assets.apps.ecpaas-dev.cdc.gov/'
  }
*/

  if (getEnvironment() === ENV_QA || getEnvironment() === ENV_DEV) {
    showCAM = true
    // 6.16.2023 JVT read from env
    urlCAM = process.env.REACT_APP_URL_CAM_DEV
  }

  if (getEnvironment() === ENV_DEV) {
    showOrgRisk = true
    // 8.9.2023 JVT read from env
    urlOrgRisk = process.env.REACT_APP_ORG_RISK_URL
  }

  // eslint-disable-next-line
  console.log('AvailableApps ' + loginSamsCode)

  if (showCAM) {
    apps.push({
      name: 'Community Assets Map',
      path: urlCAM, // process.env.REACT_APP_CAM_URL,
      icon: cam,
      target: ''
    })
  }

  if (showOrgRisk) {
    apps.push({
      name: 'Organization Risk',
      path: urlOrgRisk, // process.env.REACT_APP_ORG_RISK,
      icon: orgRisk,
      target: ''
    })
  }
  apps.push({
    name: 'Recognized Organization Site Explorer',
    // eslint-disable-next-line
    path: process.env.REACT_APP_ROSE_URL + loginSamsCode,
    icon: se,
    target: ''
  })

  apps.push({
    name: 'Enrollment Tracker',
    // eslint-disable-next-line
    path: process.env.REACT_APP_TRACKER_URL + loginSamsCode,
    icon: tracker,
    target: ''
  })

  // cdc admin
  if (user.roleIds && user.roleIds.includes(4)) {
    apps.push({
      name: 'DPRP CDC Reports',
      // eslint-disable-next-line
      // path: urlPowerBI,
      // 6.16.2023 - JVT  read URL from global var
      path: pbiCdcURL,
      //  'https://app.powerbigov.us/groups/me/reports/97598283-e91f-4e03-97dc-6658766f805f/ReportSection469c184b83a698a2726e?ctid=9ce70869-60db-44fd-abe8-d2767077fc8f',
      icon: dprpCdcReports,
      target: '_blank'
    })

    apps.push({
      name: 'Risk Assessment Module',
      // eslint-disable-next-line
      // path: urlPowerBI,
      // 6.16.2023 - JVT  read URL from global var
      path: pbiRamURL,
      //  'https://app.powerbigov.us/groups/me/reports/97598283-e91f-4e03-97dc-6658766f805f/ReportSection469c184b83a698a2726e?ctid=9ce70869-60db-44fd-abe8-d2767077fc8f',
      icon: dprpCdcReports,
      target: '_blank'
    })
  }

  // sqs role
  if (user.roleIds && user.roleIds.includes(10)) {
    apps.push({
      name: 'DPRP SQS Reports',
      path: pbiSqsURL,
      icon: dprpSqsReports,
      target: '_blank'
    })

    apps.push({
      name: 'SQS Risk Assessment Module',
      path: pbiSqsRamURL,
      icon: dprpSqsReports,
      target: '_blank'
    })
  }

  // PQS user
  if (user.roleIds && user.roleIds.includes(13)) {
    apps.push({
      name: 'DPRP PQS Reports',
      path: pbiPqsURL,
      //  'https://app.powerbigov.us/groups/43c24ac7-62ea-4e71-b277-c42d1fc6bdfb/reports/531f3043-7d9e-41f2-a66d-85d5631d3211/ReportSection1c44861bd69e579635de',
      icon: dprpPqsReports,
      target: '_blank'
    })
  }

  /*
  const apps = [
    {
      name: 'Community Assets Map t',
      path: process.env.REACT_APP_CAM_URL,
      icon: cam
    },
    {
      name: 'Recognized Organization Site Explorer',
      // eslint-disable-next-line
      path: process.env.REACT_APP_ROSE_URL + loginSamsCode,
      icon: se
    },
    {
      name: 'Enrollment Tracker',
      // eslint-disable-next-line
      path: process.env.REACT_APP_TRACKER_URL + loginSamsCode,
      icon: tracker
    }
    JT - 27.APR   Removed to transition to QA ENV
    {
      name: 'Data Discovery',
      path: process.env.REACT_APP_DATA_DISCOVERY_URL,
      icon: dash
    },
    {
      name: 'Organizational Risk & Performance',
      path: process.env.REACT_APP_ORG_RISK_URL,
      icon: orgRisk
    },
    {
      name: 'Social Media Listening',
      path: process.env.REACT_APP_SOCIAL_MEDIA_URL,
      icon: social
    },
    {
      name: 'Digital Media Insights',
      path: process.env.REACT_APP_DIGITAL_INSIGHTS_URL,
      icon: digital
    },
    {
      name: 'Path 2 Prevention',
      path: process.env.REACT_APP_P2P_URL,
      icon: p2p
    },
    {
      name: 'Bright Spot Progress Tracker',
      path: process.env.REACT_APP_BRIGHT_SPOTS_URL,
      icon: bright
    },
    {
      name: 'GraphiQL Playground',
      path: process.env.REACT_APP_GQL_API_URL_BASE,
      icon: gqlPlayground
    }
  ]
  */

  return (
    <div className='app-container'>
      <div className='app-section-title'>
        <h3>Available Apps</h3>
      </div>
      <div className='avail-apps'>
        <Grid className='module-wrapper' columns={4} stackable>
          <Grid.Row>
            {apps.map(app => (
              <Grid.Column key={app.name}>
                <ModuleCard app={{ ...app, path: app.path || defaultUrl }} isExternal />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailableApps)
