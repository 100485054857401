import { Grid, Card, Container, Header, Button } from 'semantic-ui-react'
import { ErrorBanner } from '@/layouts/Navbar/components'
import { doAnalyticsWithTimeout } from '@/lib/analytics'
import { GetEnvironment } from '../../lib/getEnvironment'
import { Carousel } from '../LandingPage/components'

import './SamsLoginPage.css'

// prettier-ignore
const timeoutText = ["For your security, your session will time out after 45 minutes of inactivity. ",
  "Any information that has not been saved will be lost. After 35 minutes of inactivity,",
  "you will receive a pop-up message indicating your remaining time. If you choose to",
  "continue your session, your time will be extended by 45 additional minutes."].join(" ")

const authSams = () => {
  //  const skipAuth = process.env.REACT_APP_SAMS_BYPASS === 'true'

  //  if (skipAuth) {
  //    window.location.href = '/'
  //    return
  //  }
  // fiorce build

  // Start Analytics
  doAnalyticsWithTimeout({
    levels: ['Chronic', 'DDT', 'diabetes', 'NDPP'],
    AzEntry: null,
    channel: 'DDT NDPP'
  })

  const environment = GetEnvironment()

  // eslint-disable-next-line no-console
  console.log({ environment })

  // eslint-disable-next-line
  console.log('authSams Click troy')
  // window.open('https://sams-stg.cdc.gov/')
  // todo env here
  // const SAMSUrl = 'https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize'
  // const SAMSKey = 'f5e684fe-4dd0-4eff-a400-14511f4925b4'
  let SAMSUrl
  let SAMSKey
  let SAMSCallbackUrl
  switch (environment) {
    case 'local':
      SAMSUrl = 'https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize'
      SAMSKey = 'f5e684fe-4dd0-4eff-a400-14511f4925b4'
      SAMSCallbackUrl = 'http://localhost:3000/samscallback'
      break
    case 'dev':
      SAMSUrl = 'https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize'
      SAMSKey = 'f5e684fe-4dd0-4eff-a400-14511f4925b4'
      SAMSCallbackUrl = 'https://ddt-ops-center.apps.ecpaas-dev.cdc.gov/samscallback'
      break
    case 'qa':
      SAMSUrl = 'https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize'
      SAMSKey = 'f5e684fe-4dd0-4eff-a400-14511f4925b4'
      SAMSCallbackUrl = 'https://ddt-ops-center-qa.apps.ecpaas-dev.cdc.gov/samscallback'
      break
    case 'int':
      SAMSUrl = 'https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize'
      SAMSKey = 'f5e684fe-4dd0-4eff-a400-14511f4925b4'
      SAMSCallbackUrl = 'https://ndpp-ops-center-int.cdc.gov/samscallback'
      break
    case 'prod':
      SAMSUrl = 'https://apigw.cdc.gov:8443/auth/oauth/v2/authorize'
      SAMSKey = '5f16d16d-ffae-4595-9729-d1c82f46038c'
      SAMSCallbackUrl = 'https://ndpp-ops-center.cdc.gov/samscallback'
      break
    default:
      // eslint-disable-next-line no-console
      console.warn('unexpected environment: ', environment)
  }
  // const SAMSUrl = 'https://apigw.cdc.gov:8443/auth/oauth/v2/authorize'
  // const SAMSKey = '5f16d16d-ffae-4595-9729-d1c82f46038c'
  // const SAMSCallbackUrl = 'https://ddt-ops-center.apps.ecpaas-dev.cdc.gov/samscallback'
  // local dev:
  // const SAMSCallbackUrl = 'http://localhost:3000/samscallback'
  // latest dev https://ddt-ops-center-qa.apps.ecpaas-dev.cdc.gov/
  // const SAMSCallbackUrl = 'https://ddt-ops-center-qa.apps.ecpaas-dev.cdc.gov/samscallback'

  const callbackParams = '&response_type=code&scope=openid+profile+email'
  const url = `${SAMSUrl}?client_id=${SAMSKey}&redirect_uri=${SAMSCallbackUrl}${callbackParams}`
  //     `${SAMSUrl}?client_id=` + SAMSKey + `&redirect_uri=" +
  // SAMSCallbackUrl + "&response_type=code&scope=openid+profile+email"
  // eslint-disable-next-line
  console.log(url)
  window.location.href = url
}

// prettier-ignore
const SamsLoginPage = () => (
  <div className='main'>
    <div className='App-header'>
      <Grid className='landing-page-container' stackable>
        <Grid.Row>
          <Grid.Column className='app-menu' computer={6} mobile={16} tablet={6}>
            <Carousel />
          </Grid.Column>
          <Grid.Column className='app-menu' computer={10} tablet={10} mobile={16}>
            <Card style={{ width: '80%', height: '500px', marginTop: '10rem', marginLeft: '3rem' }} >
              <Container text>
                <br />
                <Header as='h3'>Registered Organizations & CDC</Header>
                <Button style={{backgroundColor: '#005eaa'}} onClick={() => authSams()} color='blue'>
                  Login via SAMS</Button>
                <Header as='h3'>New User or Need Help?</Header>
                <p>If you do not have a SAMS account or need help, visit
                &nbsp;
                  <a style={{ color: '#005eaa' }} href='\help'>SAMS Help</a>.</p>
                <Header as='h3'>Session Time Out Warning</Header>
                <p>{timeoutText}</p>
              </Container>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    <ErrorBanner />
  </div>
)

export default SamsLoginPage
