import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Grid, Header, Button } from 'semantic-ui-react'
import Cookies from 'universal-cookie'

// import { useBasePath } from '@/hooks'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'

/**
 * Shows a simple page once the user has logged out.
 * @component
 */
// eslint-disable-next-line
function LoggedOut({ actions }) {
  const navigate = useNavigate()
  // const base = useBasePath()
  const cookies = new Cookies()

  useEffect(() => {
    actions.setUser({ email: 'loggedOut@cdc.gov', loggedIn: false })
  }, [actions])

  cookies.remove('opsCenterUser', { path: '/' })

  return (
    <Grid centered columns={1} style={{ marginTop: 100 }}>
      <Grid.Row>
        <Header textAlign='center'>You have been successfully logged out.</Header>
      </Grid.Row>
      <Grid.Row>
        <Button onClick={() => navigate('/')} style={{ backgroundColor: '#005eaa', color: '#FFFFFF' }}>
          Login
        </Button>
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOut)
