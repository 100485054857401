import { combineReducers } from 'redux'

const initialUserState = {
  loggedIn: false,
  firstName: 'first',
  lastName: 'last',
  email: 'first.last.skipAuth@cdc.gov',
  // email: 'lakisha.platenburg@gmail.com', // use to test SQS and PQS user
  // email: 'syddelw@yahoo.com',
  realmRoles: [],
  appRoles: [],
  username: 'first.last.skipAuth@cdc.gov', // troy added, pre sams code, displayed in User menu
  samsCodeToken: 'null'
}

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'SET_USER': {
      const { user } = action
      if (user) {
        // user.Email = user.Email;
        return user
      }
      return initialUserState
    }
    case 'CLEAR_REDUX': {
      return initialUserState
    }
    default:
      return state
  }
}

export default combineReducers({
  user: userReducer
})
