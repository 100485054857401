import { Icon } from 'semantic-ui-react'

export default function NotFound() {
  return (
    <div
      style={{
        margin: '1rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h3>
        <Icon name='warning circle' />
        404
      </h3>
      <h3>Page not found. If you believe this is an error, please contact IT help for support.</h3>
    </div>
  )
}
