import { BrowserRouter as MainRouter, Route, Routes } from 'react-router-dom'
import { ScrollToTop } from '@/components'
// import { useBasePath } from '@/hooks'
import { Default } from '@/layouts'
// eslint-disable-next-line
import { Home, LoggedOut, Help, SamsLogin, SamsCallback, PowerBI } from '@/views'

// import history from './history'
import NotFound from './NotFound'

/**
 * Router for the application
 * @component
 */
export default function Router() {
  // troy note: should be named App
  // const base = useBasePath()
  const base = []
  // <MainRouter> history={history}>

  return (
    <MainRouter basename='/'>
      <ScrollToTop>
        <Default>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path={`${base}/logout`} element={<LoggedOut />} />
            <Route exact path={`${base}/`} element={<Home />} />
            <Route exact path={`${base}/help`} element={<Help />} />
            <Route exact path={`${base}/powerbi`} element={<PowerBI />} />
            <Route exact path={`${base}/login`} element={<SamsLogin />} />
            <Route exact path={`${base}/samscallback`} element={<SamsCallback />} />
            <Route exact path={`${base}/404`} element={<NotFound />} />
            <Route element={<NotFound />} />
          </Routes>
        </Default>
      </ScrollToTop>
    </MainRouter>
  )
}
