export default function CdcQuestions() {
  return (
    <div className='cdc-footer-mobile'>
      <ul className='link-list-questions' aria-label='HAVE QUESTIONS?'>
        <li>
          <span className='fi cdc-icon-desktop x24' aria-hidden='true' />
          <a href='https://www.cdc.gov/cdc-info/index.html' target='_blank' rel='noopener noreferrer'>
            Visit CDC-INFO
          </a>
        </li>
        <li>
          <span className='fi cdc-icon-phone x24' aria-hidden='true' />
          <span>Call 800-232-4636</span>
        </li>
        <li>
          <span className='fi cdc-icon-email x24' aria-hidden='true' />
          <a href='https://wwwn.cdc.gov/dcs/contactus/form' target='_blank' rel='noopener noreferrer'>
            Email CDC-INFO
          </a>
        </li>
        <li>
          <span className='fi cdc-icon-clock_01 x24' aria-hidden='true' />
          <a href='https://www.cdc.gov/cdc-info/index.html' target='_blank' rel='noopener noreferrer'>
            Open 24-7
          </a>
        </li>
      </ul>
    </div>
  )
}
