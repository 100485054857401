/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom'
// import { ApolloProvider } from '@apollo/client'
// import { PublicClientApplication } from '@azure/msal-browser'
// import { MsalProvider } from '@azure/msal-react'

import 'semantic-ui-css/semantic.min.css'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducers from './redux/reducers'

// import client from './graphql/client'
import Router from './router/Router'
import './index.css'

const store = createStore(reducers)

ReactDOM.render(
  <Provider store={store}>
    {/* <ApolloProvider client={client}>
      <Router />
    </ApolloProvider> */}
    <Router />
  </Provider>,
  document.getElementById('root')
)
